import { Component, OnInit } from '@angular/core';
// import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
@Component({
  selector: 'app-impressies',
  templateUrl: './impressies.component.html',
  styleUrls: ['./impressies.component.css']
})
export class ImpressiesComponent implements OnInit {


  ngOnInit(): void {

  }

}
