<app-naviagation></app-naviagation>
<app-gesloten></app-gesloten>
<div class="container" style="margin-bottom: 250px">
  <div class="content-main">
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="logo_desktop" style="position: fixed; bottom: 30px ; right: 30px">
  <img src="/assets/logo/logo1.png">
</div>

<!--<div>-->
<!--  <p>Aangepaste openingsuren tijdens de feestdagen</p>-->
<!--  <ul>-->
<!--    <li>24 en 25 december enkel take away op bestelling</li>-->
<!--    <li>26 t.e.m. 30 december: 17u30 tot 00u00</li>-->
<!--    <li>31 december enkel take away op bestelling</li>-->
<!--    <li>1 januari gesloten</li>-->
<!--    <li>2 t.e.m 7 januari: 17u30 tot 00u00</li>-->
<!--  </ul>-->

<!--</div>-->
<!--<div style="position:fixed; bottom: 125px; left: 15px">-->
<!--  <p>Aangepaste openingsuren tijdens de feestdagen</p>-->
<!--  <ul>-->
<!--    <li>24 en 25 december enkel take away op bestelling</li>-->
<!--    <li>26 t.e.m. 30 december: 17u30 tot 00u00</li>-->
<!--    <li>31 december enkel take away op bestelling</li>-->
<!--    <li>1 januari gesloten</li>-->
<!--    <li>2 t.e.m 7 januari: 17u30 tot 00u00</li>-->
<!--  </ul>-->


<!--</div>-->
<div class="logo_bib" style="position: fixed; bottom: 0px ; left: 0px">
  <img src="/assets/logo/bibgoer.png">
</div>











