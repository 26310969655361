import { Component, OnInit } from '@angular/core';
import {DrankService} from '../../services/drank.service';
import {Wijnkaart} from '../../model/wijnkaart';
import {Wijn2} from '../../model/wijn2';

@Component({
  selector: 'app-wijnkaart',
  templateUrl: './wijnkaart.component.html'
})
export class WijnkaartComponent implements OnInit {
  wijnkaart!: Wijnkaart;
  dessertWijn!: Wijn2[];
  constructor(private drankService: DrankService) { }

  ngOnInit(): void {
    this.drankService.getWijnKaart()
      .subscribe((wijnkaart: Wijnkaart) => {
        this.wijnkaart = wijnkaart;
      });
    this.drankService.getDessertWijn2()
      .subscribe((dessertwijn2: Wijn2[]) => {
        this.dessertWijn = dessertwijn2;
      });
  }

}
