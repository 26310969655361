import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greedymonday',
  templateUrl: './greedymonday.component.html',
  styleUrls: ['./greedymonday.component.css']
})
export class GreedymondayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
