<div *ngIf="wijnsuggesties && wijnsuggesties.bubbels.length > 0" class="box">
  <div class="title">Bubbels</div>
  <div class="content">
    <app-wijn-kaart-item *ngFor="let item of wijnsuggesties.bubbels" [wijn]="item" ></app-wijn-kaart-item>
  </div>
</div>

<div *ngIf="wijnsuggesties && wijnsuggesties.wit.length > 0" class="box">
  <div class="title">Wit</div>
  <div class="content">
    <app-wijn-kaart-item *ngFor="let item of wijnsuggesties.wit" [wijn]="item" ></app-wijn-kaart-item>
    <div class="text_align_right">
      15cl/glas
    </div>
  </div>
</div>

<div *ngIf="wijnsuggesties && wijnsuggesties.rose.length > 0" class="box">
  <div class="title">Rose - Orange</div>
  <div class="content">
    <app-wijn-kaart-item *ngFor="let item of wijnsuggesties.rose" [wijn]="item" ></app-wijn-kaart-item>
    <div class="text_align_right">
      15cl/glas
    </div>
  </div>
</div>

<div *ngIf="wijnsuggesties && wijnsuggesties.rood.length > 0" class="box">
  <div class="title">Rood</div>
  <div class="content">
    <app-wijn-kaart-item *ngFor="let item of wijnsuggesties.rood" [wijn]="item" ></app-wijn-kaart-item>
    <div class="text_align_right">
      15cl/glas
    </div>
  </div>
</div>

