import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {KoffieThee} from '../model/koffiethee';
import {Wijnkaart} from '../model/wijnkaart';
import {HttpClient} from '@angular/common/http';
import {Gin} from '../model/gin';
import {WijnSuggestie} from '../model/wijnsuggestie';
import {Bier} from '../model/bier';
import {Wijn} from '../model/wijn';
import {AperoBierFris} from '../model/aperoBierFris';
import {Kaartitem} from '../model/kaartitem';
import {Whiskys} from '../model/whiskys';
import {Wijn2} from '../model/wijn2';
import { KaartitemDrank } from '../model/kaartitem2drank';
import { Tournee } from '../model/tournee';

@Injectable({
  providedIn: 'root'
})
export class DrankService {

  private readonly ASSETS_DATA_URL = 'assets/data/dranken';

  constructor( private http: HttpClient) { }

  public getAperBierFris(): Observable<AperoBierFris> {
    return this.http.get<AperoBierFris>(this.ASSETS_DATA_URL + '/aperoBierFris.json');
  }
  public getWijnKaart(): Observable<Wijnkaart> {
    return this.http.get<Wijnkaart>(this.ASSETS_DATA_URL + '/wijnkaart.json');
  }
  public getKoffieThee(): Observable<KoffieThee> {
    return this.http.get<KoffieThee>(this.ASSETS_DATA_URL + '/koffiethee.json');
  }
  public getGin(): Observable<Gin[]> {
    return this.http.get<Gin[]>(this.ASSETS_DATA_URL + '/ginsuggesties.json');
  }
  public getWhiskey(): Observable<Whiskys> {
    return this.http.get<Whiskys>(this.ASSETS_DATA_URL + '/whisky.json');
  }
  public getDigestieven(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/digestieven.json');
  }
  public getDigestievenGemengd(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/digestievengemengd.json');
  }
  public getGrappa(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/grappa.json');
  }
  public getWijnSuggestie(): Observable<WijnSuggestie> {
    return this.http.get<WijnSuggestie>(this.ASSETS_DATA_URL + '/wijnsuggesties.json');
  }
  public getBierSuggestie(): Observable<Bier[]> {
    return this.http.get<Bier[]>(this.ASSETS_DATA_URL + '/biersuggestie.json');
  }
  public getDessertWijn(): Observable<Wijn[]> {
    return this.http.get<Wijn[]>(this.ASSETS_DATA_URL + '/dessertwijnen.json');
  }
  public getDessertWijn2(): Observable<Wijn2[]> {
    return this.http.get<Wijn2[]>(this.ASSETS_DATA_URL + '/dessertwijnen2.json');
  }
  public getSuggestieDigestief(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/suggestiedigestief.json');
  }
  public getBierSuggesties(): Observable<KaartitemDrank[]> {
    return this.http.get<KaartitemDrank[]>(this.ASSETS_DATA_URL + '/biersuggesties.json');
  }

  public getTournee(): Observable<Tournee> {
    return this.http.get<Tournee>(this.ASSETS_DATA_URL + '/tourneeminerale.json');
  }



}
