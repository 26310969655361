import {HttpClient} from '@angular/common/http';
import {Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Gerecht} from '../model/gerecht';
import {Menuproduct} from '../model/menuproduct';
import {Kaartitem} from '../model/kaartitem';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private readonly ASSETS_DATA_URL = 'assets/data/eten';

  constructor(private http: HttpClient) {

  }

  public getMenuProducten(): Observable<Menuproduct[]> {
    return this.http.get<Menuproduct[]>(this.ASSETS_DATA_URL + '/menuproducten.json');
  }

  public getAperitiefAmu(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/aperitiefAmu.json');
  }

  public getAmukes(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/amukes.json');
  }

  public getDesserten(): Observable<Kaartitem[]> {
    return this.http.get<Kaartitem[]>(this.ASSETS_DATA_URL + '/nagerechten.json');
  }


}
