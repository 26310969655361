import {Component, Input, OnInit} from '@angular/core';
import {Kaartitem} from '../model/kaartitem';

@Component({
  selector: 'app-kaart-item',
  template: '<div class="kaartitem"><p class="item">{{kaartItem.item}}<br/><span *ngIf="kaartItem.omschrijving">{{kaartItem.omschrijving}}</span></p><p class="prijs">{{kaartItem.prijs | currency:\'EUR\'}}</p></div>'
})
export class KaartItemComponent implements OnInit {
  @Input() kaartItem!: Kaartitem;
  constructor() { }

  ngOnInit(): void {
  }

}
