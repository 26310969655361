import { Component, OnInit } from '@angular/core';
import {DrankService} from '../../services/drank.service';
import {Wijn} from '../../model/wijn';
import {KoffieThee} from '../../model/koffiethee';
import {WijnSuggestie} from '../../model/wijnsuggestie';

@Component({
  selector: 'app-wijnsuggestie',
  templateUrl: './wijnsuggestie.component.html'
})
export class WijnsuggestieComponent implements OnInit {

  wijnsuggesties!: WijnSuggestie;
  constructor(private drankenService: DrankService) { }

  ngOnInit(): void {
    this.drankenService.getWijnSuggestie()
      .subscribe((w: WijnSuggestie) => {
        this.wijnsuggesties = w;
      });
  }
}
