<div class="box">
<!--  <div class="title">Summer Take Away</div>-->
<!--  <br/>-->
<!--  <div class="content" style="text-align: center">-->

<!--    <p>-->
<!--      Tijdens de maanden juni, juli en augustus kan u bij ons ook afhaalboxen bestellen. Deze zijn verkrijgbaar op vrijdag, zaterdag en zondag.-->
<!--      Tijdens weekdagen kunt u deze bestellen vanaf 10 personen. <br/>-->
<!--      Gelieve je bestelling 3 dagen op voorhand te plaatsen via <br/>-->
<!--      mail <a-->
<!--      href="mailto: info@amu-turnhout.be">info@amu-turnhout.be</a> <br/>-->
<!--      of telefonisch op 014/41.24.89 of 0486/32.05.21.-->
<!--    </p>-->
<!--<br/>-->
<!--    <div style="margin: 25px">-->
<!--      <span style="font-size: large">BBQ-Box Amu</span><br/>-->
<!--      <p>-->
<!--        Amu pakt dit jaar uit met een gloednieuwe BBQ-box. Maak van uw BBQ een culinaire beleving, door middel van ons uitgebreid assortiment warme en koude gerechten, inclusief dessert. Zo geniet u van de fine-dining experience van Amu in uw eigen tuin.-->
<!--        Wat kan u juist verwachten?-->
<!--      </p>-->
<!--      <ul>-->
<!--        <li>Koud voorgerecht/amuse als starter van een betoverende beleving.</li>-->
<!--        <li>Warm voorgerecht een papillot gevuld met vis en aangepaste groenten om te bereiden op de BBQ.</li>-->
<!--        <li>Hoofgerecht een heerlijk stuk vlees met warme en koude groentebereidingen en een aangepaste saus om te bereiden op de BBQ.</li>-->
<!--        <li>Dessert om te bereiden op de BBQ als afsluiter van een heerlijke BBQ ervaring.</li>-->
<!--      </ul>-->
<!--      <p>Prijs: 65€ p/p.</p>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <span style="font-size: large">Free Style-box</span><br/>-->
<!--      <p>-->
<!--        Geen zin in BBQ en toch willen genieten van onze heerlijke Amukes! Ontdek onze Amu Free Style-box, een box gevuld met 6 verrassende Amukes, die enkel opgewarmd dienen te worden alvorens er heerlijk van te kunnen genieten.-->
<!--      </p>-->

<!--      <p>Prijs: 65€ p/p. (excl.dessert) <br/> Dessert: 10€ p/p.</p>-->
<!--      <br/>-->
<!--      <br/>-->
<!--      <div style="text-align: left">-->
<!--      <span style="font-size: large">Wijnen</span><br/>-->
<!--      <li>Aperitief: Wijnbier Bienne 6€</li>-->
<!--      <li>Witte wijn: Borovitza Bella Rada 15€</li>-->
<!--      <li>Orange wijn: La Vie en Orange 16.50€</li>-->
<!--      <li>Rosé wijn: Calenta Fantini 15€</li>-->
<!--      <li>Rode wijn: Zaranda Tierra Savia 16.50€</li>-->
<!--      </div>-->


<!--    <br/>-->
<!--    <div style="font-size: large">Luxe box 85 € p/p <br/><span style="font-size: small">6 feestelijke gerechten incl. brood excl. dessert</span></div>-->
<!--    <br/>-->
<!--    <div style="font-size: large">Dessert 10 € p/p <br/><span style="font-size: small"></span></div>-->
<!--    <div style="font-size: large">Aangepaste wijnen 36 € p/p <br/><span style="font-size: small">1 fles wit, 1 fles rood</span></div>-->

<!--    <p>-->
<!--      Al onze gerechten moeten enkel nog opgewarmd te worden.<br/>-->
<!--      U kan uw verassingsbox bestellen voor 24, 25 en 31 december.-->
<!--      <br/>-->
<!--      Bestellingen voor 24, 25 december ten laatste doorgeven voor 19 december <br/>-->
<!--      Bestellingen voor 31 december ten laatste doorgeven voor 26 december <br/>-->
<!--      <br/>-->
<!--      Wanneer afhalen?<br/>-->
<!--      24 december = 11u00 - 14u00 <br/>-->
<!--      25 december = 11u00 - 13u00 <br/>-->
<!--      31 december = 11u00 - 14u00 <br/>-->
<!--    </p>-->

<!--    <p>-->
<!--      Reserveren en bestellen kan via:-->
<!--      <br/> <a href="mailto:info@amu-turnhout.be">info@amu-turnhout.be</a>-->
<!--      <br/> 014/41.24.89.-->
<!--      <br/> 0486/32.05.21-->
<!--    </p>-->


    <!--    <img src="/assets/logo/lovebox_2022.png" style="max-width:100%;-->
    <!--max-height:100%;">-->


    <img style="max-width: 100%; height: auto " src="/assets/2024_feestdagen.jpg" />
</div>
