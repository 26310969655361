import {Component, Input, OnInit} from '@angular/core';
import {Kaartitem} from '../model/kaartitem';
import {Wijn} from '../model/wijn';

@Component({
  selector: 'app-wijn-kaart-item',
  templateUrl: './wijn-kaart-item.component.html',
  styleUrls: ['./wijn-kaart-item.component.css']
})
export class WijnKaartItemComponent implements OnInit {
  @Input() wijn!: Wijn;
  constructor() { }

  ngOnInit(): void {
  }

}
