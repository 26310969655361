<div class="kaartitem">


  <div style="font-size: larger; "  class="item">

    <span class="wijn_chateau" style="text-align: left" *ngIf="item.item">{{item.item}}</span>

  </div>
  <div *ngIf="item.omschrijving" class="item">
    <span class="" *ngIf="item.omschrijving">{{item.omschrijving}}<br/></span>
  </div>


  <div class="prijs">
    <span class="drank_prijs_fles"*ngIf="item.prijs.fles">{{item.prijs.fles.prijs  | currency:'EUR'}} &nbsp; {{item.prijs.fles.volume}} /fles<br/></span>
    <span class="drank_prijs_glas" *ngIf="item.prijs.glas">{{item.prijs.glas.prijs | currency:'EUR'}} <span *ngIf="item.prijs.glas.prijs"> /glas</span> <br/></span>
  </div>
<br/>
  <br/>
</div>
