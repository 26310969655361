<div class="text_align_center"  style="background: none; font-size: x-large">

<!--  <p><strong>Uitzonderlijk gesloten op maandag 6 december</strong></p>-->



    <strong>Tip voor de feestdagen schenk een cadeaubon van Amu!</strong>




<!--  <p>Aangepaste openingsuren tijdens de feestdagen</p>-->
<!--  <ul>-->
<!--    <li>24 en 25 december enkel take away op bestelling</li>-->
<!--    <li>26 t.e.m. 30 december: 17u30 tot 00u00</li>-->
<!--    <li>31 december enkel take away op bestelling</li>-->
<!--    <li>1 januari gesloten</li>-->
<!--    <li>2 t.e.m 7 januari: 17u30 tot 00u00</li>-->
<!--  </ul>-->


</div>
