import { Component, OnInit } from '@angular/core';
import { DrankService } from '../services/drank.service';
import { Tournee } from '../model/tournee';

@Component({
  selector: 'app-minerale',
  templateUrl: './minerale.component.html'
})
export class MineraleComponent implements OnInit {

  tournee: Tournee;
  constructor(private drankService: DrankService) { }

  ngOnInit(): void {
    this.drankService.getTournee()
      .subscribe((tournee: Tournee) => {
        this.tournee = tournee;
      })
  }

}
