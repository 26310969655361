
<div class="box">
  <div class="title">Champagne</div>
  <div *ngIf="wijnkaart" class="content">

    <app-wijn-kaart-item  *ngFor="let wijnkaartitem of wijnkaart.champagne" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>

  </div>
</div>

<div class="box">
  <div class="title">Wit</div>
  <div *ngIf="wijnkaart" class="content">

    <p class="subtitel">Wit fris en fruitig</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.witFrisFruitig" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>

    <p class="subtitel">Wit medium</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.witMedium" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>

    <p class="subtitel">Wit vol</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.witVol" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>



  </div>
</div>
<div class="box">
  <div class="title">Biodynamisch</div>
  <div *ngIf="wijnkaart" class="content">
    <p class="subtitel">Biodynamisch</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.witorangevoordedurvers" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>
  </div>
</div>
<div class="box">
  <div class="title">Rood</div>
  <div *ngIf="wijnkaart" class="content">

    <p class="subtitel">Rood fris en fruitig</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.roodFrisFruitig" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>

    <p class="subtitel">Rood medium</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.roodMedium" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>

    <p class="subtitel">Rood vol</p>
    <app-wijn-kaart-item *ngFor="let wijnkaartitem of wijnkaart.roodVol" [wijn]="wijnkaartitem"></app-wijn-kaart-item>
    <br/>


  </div>
</div>

<div class="box">
  <div class="title"> Dessert wijn</div>
  <div *ngIf="dessertWijn"  class="content">
    <app-wijn-kaart-item2 *ngFor="let item of dessertWijn" [wijn]="item"></app-wijn-kaart-item2>

  </div>
</div>


