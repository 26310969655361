import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MenuComponent} from './menu/menu.component';
import {DrankenComponent} from './dranken/dranken.component';
import {ReserverenComponent} from './reserveren/reserveren.component';
import {GreedymondayComponent} from './greedymonday/greedymonday.component';
import {ImpressiesComponent} from './impressies/impressies.component';
import {TakewayComponent} from './takeway/takeway.component';
import {AperitievenComponent} from './dranken/aperitieven/aperitieven.component';
import {WijnsuggestieComponent} from './dranken/wijnsuggestie/wijnsuggestie.component';
import {WijnkaartComponent} from './dranken/wijnkaart/wijnkaart.component';
import {WarmedrankenComponent} from './dranken/warmedranken/warmedranken.component';
import {DigestievenComponent} from './dranken/digestieven/digestieven.component';
import {WhiskeyComponent} from './dranken/whiskey/whiskey.component';
import {GinComponent} from './dranken/gin/gin.component';
import { BierSuggestiesComponent } from './dranken/bier-suggesties/bier-suggesties.component';
import { MineraleComponent } from './minerale/minerale.component';
import { AlcoholvrijComponent } from './dranken/alcoholvrij/alcoholvrij.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'dranken', component: DrankenComponent },
  { path: 'alcoholvrij', component: AlcoholvrijComponent },
  { path: 'minerale', component: MineraleComponent },
  { path: 'aperitief', component: AperitievenComponent },
  { path: 'wijnsuggestie', component: WijnsuggestieComponent },
  { path: 'biersuggestie', component: BierSuggestiesComponent },
  { path: 'wijnkaart', component: WijnkaartComponent },
  { path: 'warmedranken', component: WarmedrankenComponent },
  { path: 'digestieven', component: DigestievenComponent },
  { path: 'reserveren', component: ReserverenComponent },
  { path: 'greedymonday', component: GreedymondayComponent },
  // { path: 'impressies', component: ImpressiesComponent },
  { path: 'whisky', component: WhiskeyComponent },
  { path: 'gin', component: GinComponent },
    { path: 'takeaway', component: TakewayComponent },
  { path: 'impressies', component: ImpressiesComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
