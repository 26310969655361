
<div class="home">

  <div class="only_mobile">
    <div class="vrouw">
      <img src="/assets/background/vrouw_klein2.png">
    </div>
    <div class="logo" >
      <img src="/assets/logo/logo1.png">
    </div>
  </div>




</div>

