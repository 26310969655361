<div class="box">
  <div class="title">Parking</div>
  <div class="content">
    <p>
      Amu Food & Wine Bar is centraal gelegen op wandelafstand van 3 grote parkings.
    </p>
    <ol>
      <li>Parking PP41  op 150 meter <br/>Tarieven : 1,50 € per uur, maximum 6 € per dag, op zondag maximum 3 € per dag</li>
      <li>Parking Patersstraat op 230 meter <br/> Tarieven : 1,50 € per uur , vanaf  18:00 u gratis</li>
      <li>Parking Den Bond op 100 meter <br/> Tarieven = 2,00 € per uur vanaf 18:00u 1,50 € per uur. <br/> Weekend en feestdagen 1,50 € per uur</li>
    </ol>
    <p>
      Als u komt genieten van onze lunch op vrijdag en maandag, krijgt u een gratis uit rij ticket van Parking PP41.
    </p>
    <p style="font-weight: bolder">Tarieven opgesteld op 29/04/2024</p>



  </div>
</div>
