
<div class="box">
  <div class="title"> Tournée Minérale</div>
  <div *ngIf="tournee" class="content">

    <p class="subtitel" style="font-size: x-large; text-align: center; ">Aperitief</p>
    <app-kaart-item-drank *ngFor="let item of tournee.aperitief" [item]="item"></app-kaart-item-drank>
    <br/>
    <br/>
    <p class="subtitel" style="font-size: x-large; text-align: center; ">Wijn</p>
    <app-kaart-item-drank *ngFor="let item of tournee.wijn" [item]="item"></app-kaart-item-drank>
    <br/>
    <br/>
    <p class="subtitel" style="font-size: x-large; text-align: center; ">Bier</p>
    <app-kaart-item-drank *ngFor="let item of tournee.bier" [item]="item"></app-kaart-item-drank>
    <br/>

  </div>

</div>
