<div>
  <ul class="drank-nav">
    <li ><a routerLink="/" routerLinkActive="active">Aperitief - Bier - Fris</a></li>
    <li ><a routerLink="/wijnsuggesties" routerLinkActive="active">Wijnsuggesties</a></li>
    <li ><a routerLink="/wijnkaart" routerLinkActive="active">Wijnkaart</a></li>
    <!--<li><a routerLink="/biersuggesties" routerLinkActive="active">Bier suggesties</a></li>-->
    <li><a routerLink="/gin" routerLinkActive="active">Gin</a></li>
    <li><a routerLink="/whiskey" routerLinkActive="active">Whisky</a></li>
    <li><a routerLink="/digestief" routerLinkActive="active">Digestief</a></li>
    <li><a routerLink="/dessertwijn" routerLinkActive="active">Dessertwijn</a></li>
    <li><a routerLink="/koffiethee" routerLinkActive="active">Koffie & Thee</a></li>
  </ul>
</div>
