import { Component, OnInit } from '@angular/core';
import {DrankService} from '../../services/drank.service';
import {Whiskys} from '../../model/whiskys';

@Component({
  selector: 'app-whiskey',
  templateUrl: './whiskey.component.html'
})
export class WhiskeyComponent implements OnInit {

  whiskeys!: Whiskys;
  constructor(private drankenservice: DrankService) { }

  ngOnInit(): void {
    this.drankenservice.getWhiskey()
      .subscribe((whiskys: Whiskys) => {
        this.whiskeys = whiskys;
      });
  }
}
