import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserveren',
  templateUrl: './reserveren.component.html'
})
export class ReserverenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // imageObject: Array<object> = [
  //   {image: 'assets/restaurantplaatsen/bar.jpg',
  //   thumbImage: '/assets/restaurantplaatsen/bar_thumbnail.jpg',
  //   alt: 'alt of image',
  //   title: 'bar',
  //   order: 1},
  //   {image: '/assets/restaurantplaatsen/lounge.jpg',
  //   thumbImage: '/assets/restaurantplaatsen/lounge_thumbnail.jpg',
  //     title: 'lounge',
  //     alt: 'alt of image',
  //   order: 2},
  //   {image: '/assets/restaurantplaatsen/tafels.jpg',
  //     thumbImage: '/assets/restaurantplaatsen/tafels_thumbnail.jpg',
  //     title: 'tafel',
  //     alt: 'alt of image',
  //     order: 3}
  // ];
}

