import { Component, Input, OnInit } from '@angular/core';
import { Kaartitem } from '../model/kaartitem';
import { Menuproduct } from '../model/menuproduct';

@Component({
  selector: 'product',
  template: '<div class="kaartitem"><p style="text-align: center" class="item">{{item.product}}<br/></p></div>'
})
export class ProductComponent implements OnInit {

  @Input() item!: Menuproduct;
  constructor() { }

  ngOnInit(): void {
  }

}
