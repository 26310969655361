<div class="kaartitem">

  <p class="item">
    <span class="wijn_chateau"*ngIf="wijn.chateau">{{wijn.chateau}}<br/></span>
    <span class="wijn_druiven" *ngIf="wijn.druiven">{{wijn.druiven}}<br/></span>
    <span class="wijn_oorsprong"*ngIf="wijn.oorsprong">{{wijn.oorsprong}}<br/></span>
    <span class="wijn_smaak" *ngIf="wijn.omschrijving">{{wijn.omschrijving}}<br/></span>
  </p>

  <p class="prijs">
    <span class="drank_prijs_fles"*ngIf="wijn.drankprijs.fles">{{wijn.drankprijs.fles.prijs  | currency:'EUR'}} &nbsp; {{wijn.drankprijs.fles.volume}}<br/></span>
    <span class="drank_prijs_glas" *ngIf="wijn.drankprijs.glas ">{{wijn.drankprijs.glas  | currency:'EUR'}} / glas <br/></span>
  </p>

</div>
