import {Component, Input, OnInit} from '@angular/core';
import {Wijn} from '../model/wijn';
import {Wijn2} from '../model/wijn2';

@Component({
  selector: 'app-wijn-kaart-item2',
  templateUrl: './wijn-kaart-item2.component.html',
  styleUrls: ['./wijn-kaart-item2.component.css']
})
export class WijnKaartItem2Component implements OnInit {
  @Input() wijn!: Wijn2;
  constructor() { }

  ngOnInit(): void {
  }

}
