import { Component, OnInit } from '@angular/core';
import {Gerecht} from '../model/gerecht';
import {MenuService} from '../services/menu.service';
import {Menuproduct} from '../model/menuproduct';
import {Kaartitem} from '../model/kaartitem';
import { DrankService } from '../services/drank.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  menuproducuten!: Menuproduct[];

  amukes!: Kaartitem[];
  desserten!: Kaartitem[];
  suggestieDigestief!: Kaartitem[];
  constructor(private menuService: MenuService,
              private drankService: DrankService) { }

  ngOnInit(): void {
    this.laden();
  }

  public laden(): void {
    this.menuService.getMenuProducten()
      .subscribe((menuProducten: Menuproduct[]) => {
      this.menuproducuten = menuProducten;
    });

    this.menuService.getAmukes()
      .subscribe((amukes: Kaartitem[]) => {
        this.amukes = amukes;
      });
    this.menuService.getDesserten()
      .subscribe((desserten: Kaartitem[]) => {
        this.desserten = desserten;
      });
    this.drankService.getSuggestieDigestief()
      .subscribe((suggestieDigestieven: Kaartitem[]) => {
        this.suggestieDigestief = suggestieDigestieven;
      });
  }

}
