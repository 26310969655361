import { Component, Input, OnInit } from '@angular/core';
import { Wijn2 } from '../../model/wijn2';
import { KaartitemDrank } from '../../model/kaartitem2drank';

@Component({
  selector: 'app-kaart-item-drank',
  templateUrl: './kaart-item-drank.component.html',
  styleUrls: ['./kaart-item-drank.component.scss']
})
export class KaartItemDrankComponent implements OnInit {

  @Input() item!: KaartitemDrank;
  constructor() { }

  ngOnInit(): void {
  }

}
