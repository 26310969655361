<!--<div class="grid-item"-->
<!--     *ngFor="let item of items; let i = index"-->
<!--     [lightbox]="i">-->
<!--&lt;!&ndash;  <img [src]="item.data.thumbnail">&ndash;&gt;-->
<!--</div>-->
<img src="/assets/Advertentie_algemeen-1.png" style="display: block;  margin-left: auto;  margin-right: auto; width: 50%;">
<!--<div style="margin-top: 5%">-->
<!--  <object data="/assets/Advertentie_algemeen.pdf" type="application/pdf" width="100%" height="500px">-->
<!--    <p>Unable to display PDF file. <a href="/assets/Advertentie_algemeen.pdf">Download</a> instead.</p>-->
<!--  </object>-->
<!--</div>-->

